import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, matchPath } from 'react-router-dom'
import { Typography, Button, TextField, Link, Box, Stack } from '@mui/material'
import { SENT_RESET_EMAIL } from 'constants/messages'
import { trackEvent } from '../../../../utils/analytics'
import LoginContent from '../shared/login-content'
import LoginTitleWrap from '../shared/login-title-wrap'
import { closeLoginSlide } from '../../../../actions/checkout.actions'
import { checkValidUser } from '../../../../actions/auth0.actions'
import withAuth from 'hoc/with-auth'
import { formatTimer } from 'utils/helpers'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import useSupportEmail from '../../../../hooks/useSupportEmail'

const defaultTitle = 'Forgot Password?'
const defaultSubTitle = 'Please enter your email. We will send you an email to reset your password.'
const ctaText = 'Reset Password'
const DATA_CY_PREFIX = 'forgot-password-form'

function ForgotPassword({
  title = defaultTitle,
  subtitle = defaultSubTitle,
  email: emailProp,
  onPasswordResetEmailSent,
  passwordResetEmailSent,
  passwordResetRequired,
  ...authProps
}) {
  const dispatch = useDispatch()
  const { supportEmail } = useSupportEmail()
  const [email, setEmail] = useState(emailProp)
  const [error, setError] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const [resetTimer, setResetTimer] = useState(null)
  const timerIntervalRef = useRef(null)
  const sentEmailRef = useRef(false)

  useEffect(() => {
    if (passwordResetRequired) {
      setResetTimer(0)
      const intervalId = setInterval(() => {
        setResetTimer(ptimer => ptimer + 1)
      }, 1000)
      timerIntervalRef.current = intervalId
    }
  }, [])

  useEffect(() => {
    if (resetTimer >= 60) {
      setResetTimer(null)
      sentEmailRef.current = true
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
    }
  }, [resetTimer])

  const handleCloseLoginSlide = toggle => dispatch(closeLoginSlide(toggle))

  const handleReset = async () => {
    const { forgotPassword } = authProps
    try {
      let userExists = false
      try {
        await checkValidUser(email, true)
        userExists = true
      } catch (_err) {
        userExists = false
      }
      if (userExists) {
        if (forgotPassword) {
          await forgotPassword(email?.trim().toLowerCase())
        }
        setMessage(SENT_RESET_EMAIL)
        setResetTimer(0)
        onPasswordResetEmailSent()
        const intervalId = setInterval(() => {
          setResetTimer(ptimer => ptimer + 1)
        }, 1000)
        timerIntervalRef.current = intervalId
      } else {
        setError('INVALID_EMAIL')
      }
    } catch (error) {
      if (error?.message === 'USER_NOT_FOUND') {
        setError('INVALID_EMAIL')
      } else {
        setError(error?.message)
      }
    }
  }

  const handleResendEmail = async e => {
    e.preventDefault()
    try {
      const { forgotPassword } = authProps
      const isSkinQuizFlow = matchPath(window.location.pathname, {
        path: '/quiz/signup',
        exact: true,
        strict: false
      })
      const isEyeQuizFlow = matchPath(window.location.pathname, {
        path: '/quiz/eye/signup',
        exact: true,
        strict: false
      })
      if (forgotPassword) {
        await forgotPassword(email?.trim().toLowerCase())
      }

      if (!isSkinQuizFlow && !isEyeQuizFlow) {
        trackEvent('login_cta', {
          CTA: { ctaText: 'Resend Email' },
          type: 'Resend Email'
        })
      }
      setMessage(SENT_RESET_EMAIL)
      setResetTimer(0)
      const intervalId = setInterval(() => {
        setResetTimer(ptimer => ptimer + 1)
      }, 1000)
      timerIntervalRef.current = intervalId
    } catch (error) {
      if (error?.message === 'USER_NOT_FOUND') {
        setError('INVALID_EMAIL')
      } else {
        setError(error?.message)
      }
    }
  }

  const sectionSubtitle = !passwordResetEmailSent
    ? subtitle
    : `We have sent an email to ${email}. <strong>Please check your email</strong> and click on the link to reset your password.`

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        pb: 2
      }}
    >
      <LoginContent
        component="form"
        onSubmit={ev => {
          ev.preventDefault()
          handleReset()
        }}
      >
        <LoginTitleWrap>
          <Typography style={{ color: '#303C42' }} variant="h3" data-cy={`${DATA_CY_PREFIX}-title`}>
            {!passwordResetEmailSent ? title : 'Check Your Email!'}
          </Typography>
          <Typography
            variant="body2"
            data-cy={`${DATA_CY_PREFIX}-sub-title`}
            dangerouslySetInnerHTML={{ __html: sectionSubtitle }}
          />
          {sentEmailRef.current &&
          (passwordResetEmailSent || passwordResetRequired) &&
          resetTimer !== null ? (
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <CheckCircleIcon color="success" sx={{ fontSize: '16px' }} />
              <Typography variant="footnote" color="success.main">
                A new link has been sent to you email
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          {(passwordResetEmailSent || passwordResetRequired) && (
            <Box mt={2}>
              {resetTimer !== null ? (
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography variant="body2" color="gray.darkGray">
                    {`Didn't receive the link? Resend in `}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {formatTimer(60 - resetTimer)}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  data-cy={`${DATA_CY_PREFIX}-forgot-password-link`}
                  data-testid="email-password-form-forgot-password-link"
                  variant="hyperlink"
                  color="primary"
                  onClick={handleResendEmail}
                >
                  Resend email
                </Typography>
              )}
            </Box>
          )}
        </LoginTitleWrap>

        {!passwordResetEmailSent && !passwordResetRequired && (
          <>
            <TextField
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              value={email}
              autoFocus
            />

            {error &&
              (error === 'INVALID_EMAIL' ? (
                <Typography
                  variant="footnote"
                  align="left"
                  color="error"
                  data-cy={`${DATA_CY_PREFIX}-email-not-found-error-text`}
                  data-testid="email-password-form-email-not-found-error-text"
                >
                  Email not found. Please take{' '}
                  <Link
                    component={RouterLink}
                    to="/quiz/start"
                    color="error"
                    data-cy={`${DATA_CY_PREFIX}-email-not-found-error-take-quiz-link`}
                    data-testid="email-password-form-email-not-found-error-take-quiz-link"
                    onClick={handleCloseLoginSlide}
                  >
                    the Skin Genome Quiz
                  </Link>
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  data-cy={`${DATA_CY_PREFIX}-auth-error-text`}
                >
                  {error}
                </Typography>
              ))}

            <Button
              variant="contained"
              color="secondary"
              disabled={!email}
              fullWidth
              type="submit"
              data-cy={`${DATA_CY_PREFIX}-reset-password-button`}
            >
              {ctaText}
            </Button>
          </>
        )}

        {!passwordResetEmailSent && passwordResetRequired && (
          <>
            {error &&
              (error === 'INVALID_EMAIL' ? (
                <Typography
                  variant="footnote"
                  align="left"
                  color="error"
                  data-cy={`${DATA_CY_PREFIX}-email-not-found-error-text`}
                  data-testid="email-password-form-email-not-found-error-text"
                >
                  Email not found. Please take{' '}
                  <Link
                    component={RouterLink}
                    to="/quiz/start"
                    color="error"
                    data-cy={`${DATA_CY_PREFIX}-email-not-found-error-take-quiz-link`}
                    data-testid="email-password-form-email-not-found-error-take-quiz-link"
                    onClick={handleCloseLoginSlide}
                  >
                    the Skin Genome Quiz
                  </Link>
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  data-cy={`${DATA_CY_PREFIX}-auth-error-text`}
                >
                  {error}
                </Typography>
              ))}
          </>
        )}
      </LoginContent>

      <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="footnote" color="gray.darkGray">
          {`Need help?`}
        </Typography>
        <Typography variant="footnote" color="gray.darkGray">
          {`Email us: `}
          <Link href={`mailto:${supportEmail}`} component="a">
            {supportEmail}
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default withAuth(ForgotPassword)
