import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SIGNIN_PASSWORD_FAIL,
  SIGNIN_USER_NOT_FOUND,
  TOKEN_EXPIRED,
  USER_NOT_FOUND
} from '../../../../constants/actionTypes'
import {
  Button,
  Typography,
  Box,
  Link,
  TextField,
  InputAdornment,
  IconButton,
  Stack
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ALL_LANDING_PAGES } from '../../../../constants/configs/landing'
import { Link as RouterLink } from 'react-router-dom'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import ProvenIcon from '../../../shared/proven-icon'
import { withSupportEmail } from '../../../../hooks/useSupportEmail'
import { closeLoginSlide, toggleLoginSlide } from '../../../../actions/checkout.actions'
import { clearAuth, clearPasswordlessAuth } from '../../../../actions/auth.actions'
import { updateAccountInfo } from '../../../../actions/account.actions'
import LoginTitleWrap from '../shared/login-title-wrap'
import PasswordReset from '../../../pages/login/forgot-password-form'
import LoginContent from '../shared/login-content'
import { formatTimer } from '../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import withAuth from '../../../../hoc/with-auth'

const DATA_CY_PREFIX = 'email-password-form'

function EmailPasswordForm({ email, supportEmail, signIn, startPasswordlessAuth }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { prepopulatedEmail, error, message, passwordless } = useSelector(state => state.auth)
  const passwordlessError = passwordless?.error
  const {
    info: { isMagicLinkSent, magicLinkEmail }
  } = useSelector(state => state.account)

  const [username, setUsername] = useState(prepopulatedEmail || email)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [resetTimer, setResetTimer] = useState(null)
  const timerIntervalRef = useRef(null)
  const sentEmailRef = useRef(null)

  useEffect(() => {
    setResetTimer(0)
    const intervalId = setInterval(() => {
      setResetTimer(prev => prev + 1)
    }, 1000)
    timerIntervalRef.current = intervalId
  }, [])

  useEffect(() => {
    if (resetTimer >= 60) {
      setResetTimer(null)
      sentEmailRef.current = true
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current)
      }
    }
  }, [resetTimer])

  const onToggleEmailPassword = e => {
    console.log('onToggleEmailPassword')
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch(closeLoginSlide())
    dispatch(
      toggleLoginSlide({
        view: 2,
        navBtnTxt: 'Back',
        closeFunc: closeLoginSlide
      })
    )
  }

  const clearAllAuthErrors = () => {
    clearAuth()
    clearPasswordlessAuth()
  }

  const togglePasswordReset = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setPasswordReset(prev => !prev)
    setPasswordResetEmailSent(false)
    clearAllAuthErrors()
  }

  const handleLoginWithPassword = async e => {
    e.preventDefault()
    dispatch(updateAccountInfo({ isMagicLinkSent: false }))
    await signIn?.(username.trim(), password)
  }

  const isLandingPage = ALL_LANDING_PAGES.includes(history.location.pathname)
  const subTitleMagicLink = `We have sent an email to ${magicLinkEmail}. <strong>Please check your email</strong> and click on your personalized link.`

  return (
    <>
      {passwordReset ? (
        <PasswordReset
          onLogin={togglePasswordReset}
          email={username}
          onPasswordResetEmailSent={() => setPasswordResetEmailSent(prev => !prev)}
          passwordResetEmailSent={passwordResetEmailSent}
        />
      ) : (
        <>
          {isMagicLinkSent === true && !error ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
                pb: 2
              }}
            >
              <LoginContent>
                <LoginTitleWrap isMagicLinkForm={true} isFromQuiz={true}>
                  <Typography variant="h3" data-cy={`${DATA_CY_PREFIX}-title`}>
                    Check Your Email!
                    <br />
                  </Typography>
                  <Typography
                    variant="body2"
                    data-cy={`${DATA_CY_PREFIX}-sub-title`}
                    dangerouslySetInnerHTML={{ __html: subTitleMagicLink }}
                  />
                  {sentEmailRef.current && resetTimer !== null ? (
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                      <CheckCircleIcon color="success" sx={{ fontSize: '16px' }} />
                      <Typography variant="footnote" color="success.main">
                        A new link has been sent to you email
                      </Typography>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  <Box mt={2}>
                    {resetTimer !== null ? (
                      <Stack flexDirection="row" alignItems="center" gap={0.5}>
                        <Typography variant="body2" color="gray.darkGray">
                          {`Didn't receive the link? Resend in `}
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {formatTimer(60 - resetTimer)}
                        </Typography>
                      </Stack>
                    ) : (
                      <>
                        <Typography
                          variant="hyperlink"
                          color="primary"
                          component="div"
                          disabled={isSending}
                          onClick={async () => {
                            setIsSending(true)
                            await startPasswordlessAuth(magicLinkEmail)
                            setIsSending(false)
                            setResetTimer(0)
                            const intervalId = setInterval(() => {
                              setResetTimer(prev => prev + 1)
                            }, 1000)
                            timerIntervalRef.current = intervalId
                          }}
                          data-cy={`${DATA_CY_PREFIX}-send-magic-link-again-text`}
                        >
                          Resend email
                        </Typography>
                        <Typography
                          variant="hyperlink"
                          color="primary"
                          component="div"
                          sx={{
                            pt: '24px !important'
                          }}
                          disabled={isSending}
                          onClick={onToggleEmailPassword}
                          data-cy={`${DATA_CY_PREFIX}-sign-in-with-pasword-text`}
                        >
                          Sign in with password
                        </Typography>
                      </>
                    )}
                  </Box>
                </LoginTitleWrap>
              </LoginContent>
              <Box
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography variant="footnote" color="gray.darkGray">
                  {`Need help?`}
                </Typography>
                <Typography variant="footnote" color="gray.darkGray">
                  {`Email us: `}
                  <Link href={`mailto:${supportEmail}`} component="a">
                    {supportEmail}
                  </Link>
                </Typography>
              </Box>
            </Box>
          ) : (
            <LoginContent>
              <LoginTitleWrap>
                <Typography variant="h4" data-cy={`${DATA_CY_PREFIX}-title`}>
                  Sign In with Password
                </Typography>
                <Typography variant="body2">
                  View your unique skincare recommendations and your PROVEN formula and routine.
                </Typography>
              </LoginTitleWrap>
              <Box
                component="form"
                onSubmit={handleLoginWithPassword}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, textAlign: 'center' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    [onDesktop]: {
                      gap: 2
                    }
                  }}
                >
                  <TextField
                    onChange={e => setUsername(e.currentTarget.value)}
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    value={username}
                    autoFocus={!prepopulatedEmail}
                    error={!!error}
                  />
                  <TextField
                    name="password"
                    onChange={e => setPassword(e.currentTarget.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(prev => !prev)}>
                            <ProvenIcon
                              type="system"
                              name={showPassword ? 'password-hidden' : 'password-shown'}
                              fontSize="medium"
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    autoFocus={!!prepopulatedEmail}
                    error={!!error}
                  />
                </Box>

                {(error === USER_NOT_FOUND || passwordlessError) && (
                  <Box display="flex">
                    <ProvenIcon type="system" name="alert" color="error" sx={{ marginRight: 1 }} />
                    {passwordlessError === TOKEN_EXPIRED ? (
                      <Typography
                        variant="footnote"
                        align="left"
                        color="error"
                        data-cy={`${DATA_CY_PREFIX}-email-not-found-error-text`}
                        data-testid="email-password-form-email-not-found-error-text"
                      >
                        This link has expired, we just sent you a new one to your email address.
                      </Typography>
                    ) : (
                      <Typography
                        variant="footnote"
                        align="left"
                        color="error"
                        data-cy={`${DATA_CY_PREFIX}-email-not-found-error-text`}
                        data-testid="email-password-form-email-not-found-error-text"
                      >
                        Email not found. Please take{' '}
                        <Link
                          component={RouterLink}
                          to="/quiz/start"
                          color="error"
                          data-cy={`${DATA_CY_PREFIX}-email-not-found-error-take-quiz-link`}
                          data-testid="email-password-form-email-not-found-error-take-quiz-link"
                          onClick={closeLoginSlide}
                        >
                          the Skin Genome Quiz
                        </Link>
                      </Typography>
                    )}
                  </Box>
                )}

                {error === SIGNIN_PASSWORD_FAIL && (
                  <Box display="flex">
                    <ProvenIcon type="system" name="alert" color="error" sx={{ marginRight: 1 }} />
                    <Typography
                      variant="footnote"
                      align="left"
                      color="error"
                      data-cy={`${DATA_CY_PREFIX}-password-exists-error-text`}
                    >
                      A password exists for this email. If you no longer remember it, please{' '}
                      <Link
                        component={RouterLink}
                        color="error"
                        onClick={togglePasswordReset}
                        data-cy={`${DATA_CY_PREFIX}-password-exists-error-reset-password-link`}
                      >
                        reset password
                      </Link>
                    </Typography>
                  </Box>
                )}

                {error === SIGNIN_USER_NOT_FOUND && (
                  <Box display="flex">
                    <ProvenIcon type="system" name="alert" color="error" sx={{ marginRight: 1 }} />
                    <Typography
                      variant="footnote"
                      align="left"
                      color="error"
                      data-cy={`${DATA_CY_PREFIX}-password-exists-error-text`}
                    >
                      Email not found. Please take{' '}
                      <Link
                        component={RouterLink}
                        to="/quiz/start"
                        color="error"
                        data-cy={`${DATA_CY_PREFIX}-email-not-found-error-take-quiz-link`}
                        data-testid="email-password-form-email-not-found-error-take-quiz-link"
                        onClick={closeLoginSlide}
                      >
                        the Skin Genome Quiz
                      </Link>
                    </Typography>
                  </Box>
                )}

                {![USER_NOT_FOUND, SIGNIN_PASSWORD_FAIL].includes(error) && message && (
                  <Box display="flex">
                    <ProvenIcon type="system" name="alert" color="error" sx={{ marginRight: 1 }} />
                    <Typography variant="footnote" align="left" color="error">
                      {message}
                    </Typography>
                  </Box>
                )}

                <Typography
                  data-cy={`${DATA_CY_PREFIX}-forgot-password-link`}
                  data-testid="email-password-form-forgot-password-link"
                  variant="hyperlink"
                  color="gray.darkGray"
                  onClick={togglePasswordReset}
                >
                  Forgot password?
                </Typography>

                {!isLandingPage && (
                  <Button
                    data-cy={`${DATA_CY_PREFIX}-login-button`}
                    data-testid="email-password-form-login-button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={!username || !password}
                    sx={{ mt: 1 }}
                    type="submit"
                  >
                    Login
                  </Button>
                )}
              </Box>
            </LoginContent>
          )}
        </>
      )}
    </>
  )
}

export default withSupportEmail(withAuth(EmailPasswordForm))
