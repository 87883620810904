import propTypes from 'prop-types'
import { append, tail, without } from 'ramda'
import React, { useEffect } from 'react'
import ProvenMultipleQuizSelector from '../../../shared/proven-multiple-quiz-selector'
import { Box } from '@mui/material'
import ConcernMultipleQuizSelector from '../../../shared/proven-multiple-quiz-selector/concern'
import { QUESTION_STYLE, QUESTIONNAIRE_ID_ACNE } from '../../../../constants/configs/questionnaire'

// this style is used when the multple selector is inside a sidebar
export const SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE = 'check-box-with-sidebar'
export const SELECT_MULTIPLE_LIGHT_GREEN_STYLE = 'light-green'

function SelectMultiple({
  items,
  name,
  answer,
  _id,
  setAnswer,
  questionnaireId,
  maxAnswers,
  multipleSelectorStyle,
  style: questionStyle,
  isMobile
}) {
  useEffect(() => {
    if (!answer) {
      setAnswer({ [_id]: [] }, questionnaireId)
    }
  }, [])

  const selectAnswer = ({ value, checked }) => {
    const newValue = checked ? append(+value, answer) : without([+value], answer)

    const limit = maxAnswers && newValue.length > maxAnswers
    setAnswer(
      {
        [_id]: limit ? tail(newValue) : newValue
      },
      questionnaireId
    )
  }

  const buildStyle = (questionName, questionStyle = QUESTION_STYLE.SELECT_MULTIPLE_DOUBLE) => {
    switch (questionStyle) {
      case QUESTION_STYLE.SELECT_MULTIPLE_STANDARD:
      case QUESTION_STYLE.SELECT_MULTIPLE_SHORT:
        return { display: 'flex', flexWrap: 'wrap', gap: isMobile ? 1 : 2 }
      case QUESTION_STYLE.SELECT_MULTIPLE_LONG:
        return isMobile
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 1,
              alignSelf: 'stretch'
            }
          : { display: 'flex', flexWrap: 'wrap', gap: 2 }
      case QUESTION_STYLE.SELECT_MULTIPLE_DOUBLE:
      default:
        if (questionName === 'concern') {
          if (multipleSelectorStyle === SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE) {
            return {
              display: 'flex',
              justifyContext: 'space-between',
              gap: { xs: 2, md: 3 }
            }
          }
          return {
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
            gridAutoRows: '1fr',
            gap: { xs: 2, md: 3 }
          }
        } else {
          return { display: 'flex', flexWrap: 'wrap', gap: 1 }
        }
    }
  }

  const styleBox = buildStyle(name, questionStyle)

  // we dont use ConcernMultipleQuizSelector for acne quiz firt question since it changes style dynamically depending on multipleSelectorStyle
  // when we implement multipleSelectorStyle for the rest of quizzes we'd need to unify ConcernMultipleQuizSelector and ProvenMultipleQuizSelector
  if (name === 'concern' && QUESTIONNAIRE_ID_ACNE !== questionnaireId) {
    return (
      <Box sx={styleBox}>
        {items.map(({ text, value, subline }) => (
          <ConcernMultipleQuizSelector
            label={text}
            subline={subline}
            value={value}
            name={name}
            onChange={selectAnswer}
            key={text}
            selected={answer && answer.includes(value)}
            multipleSelectorStyle={multipleSelectorStyle}
            questionStyle={questionStyle}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box sx={styleBox}>
      {items.map(({ text, value, subline }) => (
        <ProvenMultipleQuizSelector
          label={text}
          subline={subline}
          value={value}
          name={name}
          onChange={selectAnswer}
          key={text}
          selected={answer && answer.includes(value)}
          multipleSelectorStyle={multipleSelectorStyle}
          isMobile={isMobile}
          questionStyle={questionStyle}
        />
      ))}
    </Box>
  )
}

SelectMultiple.propTypes = {
  answer: propTypes.array,
  name: propTypes.string,
  _id: propTypes.string,
  items: propTypes.array,
  maxAnswers: propTypes.number,
  setAnswer: propTypes.func,
  questionnaireId: propTypes.string
}

export default SelectMultiple
